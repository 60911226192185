@import "../globle/css-variables.scss";
.nodeContainer {
  font-family: $rogers-font;
  padding: 5px;
  width: 100%;
  //added by node team
}
.nodeBasicDetailsCont {
  display: flex;
  border-bottom: 1px solid var(--greyLight);
}
.nodeBasicInfoSm {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}
.inline-values {
  display: flex;
  flex-wrap: wrap;
  // gap: 12px;
  align-items: center;
  line-height: 1.2;
  row-gap: 3px;
}

.inline-values span {
  white-space: nowrap;
  display: inline-flex;
}

.value {
  white-space: nowrap;
}
.nodeHeaderExpand {
  display: flex;
}
.vhubPeersContainer {
  display: grid;
  grid-template-columns: repeat(6, 50px); 
  gap: 1px;
  justify-content: start; 
}

.vhubPeersContainerSM{
  display: grid;
  grid-template-columns: repeat(5, 50px); 
  gap: 1px;
  justify-content: start; 

}
.vhubLink {
  color: var(--link);
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  &:hover {
    text-decoration: underline;
  }
}

.vhubLink:not(:last-child)::after{
content: ",";
}
span ~ span.vhubLink:not(:last-of-type)::after {
  content: ",";
}

.nodeBasicInfoSm {
  display: inline-flex;
  width: 100%;
}
.nodeContainerSm {
  font-family: "Ted Next";
  font-size: 13px;
  padding: 5px 0px;
  display: flex;
  width: 100%;
  align-items: center;
}
.nodeButtons {
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 10px 5px;
}

.nodeButtonsSm {
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding-left: 3px;
}

.nodeButtons > div > button.appMenuBtn > svg,
.nodeButtonsSm > div > button.appMenuBtn > svg {
  height: 20px;
  width: 20px;
}

.nodeInfo {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.vhub {
  font-size: 12px;
  border-radius: 5px;
  background-color: var(--link);
  padding: 2px 5px;
  color: var(--white);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.nodeLink {
  color: var(--link);
  cursor: pointer;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
}
.mdPeerLink {
  color: var(--link);
  cursor: pointer;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
}

.linkStyle {
  color: var(--link);
  cursor: pointer;
  font-weight: 400 !important;
  text-decoration: underline;
}

span ~ span.nodeLink:not(:last-of-type)::after {
  content: ", ";
}

span ~ span.mdPeerLink:not(:last-of-type)::after {
  content: ", ";
}

.smt {
  color: var(--link);
}
.smtChips {
  display: flex;
  flex-direction: row;
}

.smtClear {
  background-color: transparent;
  color: var(--link);
  border: 1px solid var(--link);
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 25px;
  width: 160px;
}
.telemetryTabHead {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #fafafa;
}
.flippedTable {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
}
#custMenu {
  font-size: 20px;
  color: var(--pureBlack);
}
//new CSS Changes for node basic Details section
.nodeDetails {
  font-size: 13px;
  font-weight: 400;
  width: 25%;
}
section.nodeDetails h4 {
  margin-bottom: 5px;
}

section.nodeDetails p h5 {
  font-size: 13px;
  font-weight: 600;
}

section.nodeDetails .nodeSummaryDiv div,
section.nodeDetails .nodeSummaryDiv div p {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
section.nodeDetails .nodeSummaryDiv div p {
  margin-bottom: 2px;
  margin-right: 10px;
}

section.nodeDetails .nodeSummaryDiv div p span:first-child {
  margin-right: 5px;
}

section.nodeDetailsSm {
  display: flex;
  width: 30%;
  align-items: center;
}
section.nodeDetailsSm:last-child {
  display: flex;
  width: 40%;
  align-items: center;
}
section.nodeDetails .nodeSummaryDiv div p span.vhub,
section.nodeDetailsSm div span.vhub {
  margin: 0px 5px;
}
.vhub {
  margin-left: 10px;
}

section.nodeDetails p.scoreHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
}
section.nodeDetails span.betaLabel {
  color: var(--link);
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

section.teleScoreCont,
section.teleDeviceStatsCont {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
}
section.teleScoreCont {
  width: 25%;
}
section.teleDeviceStatsCont {
  width: 30%;
}
section.teleDeviceStatsCont p {
  width: 49%;
}
section.teleDeviceStatsCont p span {
  margin-right: 5px;
}
section.runTeleSec {
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
}
section.runTeleSec p {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
}

div.modemTypeContainer .custDDBtn {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 5px;
}

/* .nodeSummaryDiv {
  background: var(--white);
  padding: 5px;
  border: 1px solid var(--greyLight);
  border-radius: 5px;
}
 */
.nodeContainerSm
  > div
  > .nodeInfo
  > .startButtonSection
  > .startButtonSectionDropdown {
  display: flex;
  flex-direction: row;
}

.nodeContainer
  > div
  > .nodeInfo
  > .startButtonSection
  > .startButtonSectionDropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 170px;
}
section.runTeleSec div.teleActionCont {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
}
section.runTeleSec .diagButHolder,
div.runTeleSecSm .diagButHolder {
  margin-left: 10px;
}
section.runTeleSec .diagButHolder button.refreshCertiBtn,
div.runTeleSecSm .diagButHolder button.refreshCertiBtn {
  font-size: 12px;
  border-radius: 5px;
}
section.runTeleSec .diagButHolder button.refreshCertiBtn svg,
div.runTeleSecSm .diagButHolder button.refreshCertiBtn svg {
  font-size: 15px;
}
p.teleSwitchCont {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
div.teleSeleCont {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.mainNodeInfoSm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nodeDetailsSmSec1 {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 10px;
  flex-grow: 1;
}

.nodeDetailsSmSec2 {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 1;
  justify-content: space-around;
}

.nodeInfoSmSec3 {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 2;
  justify-content: space-around;
}
section.nodeDetailsSm div.nodeDataEle {
  margin-right: 10px;
}

section.nodeDetailsSm .runTeleSecSm {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.nodeDetailsSm .runTeleSecSm p {
  margin-bottom: 0px;
}

section.nodeDetailsSm .runTeleSecSm .modemTypeContainer {
  margin-top: 0px;
}
.modemTypeContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0px;
}

.modemTypeContainerText {
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
}

.multiSelectSelectedSpan {
  color: var(--black);
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 60px;
  vertical-align: middle;
  display: inline-block;
}

.startButtonSection {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 5px 3% 0px 0px;
}

.startButtonSectionDropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modemTypeContainer {
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
}

.modemTypeContainerGap {
  gap: 2vw;
}

.modemTypeContainerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
}

.controlsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
}
.mlButtonHolder {
  margin-left: auto;
  margin-right: auto;
}

/* .modemTypeContainerText {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  white-space: nowrap;
} */

.multiSelectSelectedSpan {
  color: var(--black);
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 60px;
  vertical-align: middle;
  display: inline-block;
}

.smHealthScore {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px 0px;
}
.smHealthScore > span {
  align-items: center;
  display: inline-flex;
}

.nodeDetails .smHealthScore:last-child {
  width: 100%;
}
.nodeDetailsSm .smHealthScore {
  width: 50%;
}
.scoreDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.scoreDivOverRide {
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
  gap: 3px 5px;
  max-width: 559px;
}

.summaryDiv {
  gap: 5px 15px;
}

.diagButHolder > button.refreshCertiBtn {
  padding: 3px 5px;
}

.lastSec {
  display: flex;
  flex-direction: row;
  margin: auto;
}

.smHealthScore span:last-child {
  margin: 0px 5px;
}

span.scoreChip {
  color: var(--white);
  font-size: 11px;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  border-radius: 15px;
  margin-right: 5px;
  padding: 1px 10px;
}
span.passChip {
  border: 1px solid var(--success);
  background-color: var(--success);
}
span.failChip {
  border: 1px solid var(--danger);
  background-color: var(--danger);
}
span.marginalChip {
  border: 1px solid var(--marginal);
  background-color: var(--marginal);
}

.nodeDetails p {
  margin-bottom: 2px;
  margin-left: 2px;
  display: inline-flex;
}

.nodeDetails span:nth-child(odd),
.nodeDetailsSm span:nth-child(odd) {
  font-weight: 600;
  min-width: fit-content;
}
#nodeID,
#smtID {
  font-size: 16px;
  font-weight: 700;
}
#nodeNameSM {
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
}
.serviceAreas {
  display: inline-flex;
  margin-right: 15px;
}
div.serviceAreas button {
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0px;
  font-size: inherit;
}

.vhubConatiner {
  display: "flex";
  flex-direction: row;
}

#serviceAreas,
#pCStatusDd,
#serviceAreasSm {
  background: transparent;
  color: var(--pureBlack);
  border: transparent;
  font-weight: 400;
}

hr.MuiDivider-vertical {
  width: 2px;
  height: auto;
  margin: 0px 20px;
}

.nodeTglBtnCont {
  padding: 15px 5px;
  display: flex;
  align-items: flex-start;
}
.nodeTglBtnContSm {
  padding: 10px 5px;
  display: flex;
  align-items: center;
}
.nodeTglBtnCont svg,
.nodeTglBtnContSm svg {
  display: block;
  margin: 0px 5px;
}
main.main-sm-section .nodeTglBtnCont {
  width: calc(95vw - 15%);
}
.nodeTglBtn {
  border-bottom-left-radius: 100px;
  border: 1px solid var(--greyLight);
  border-bottom-right-radius: 100px;
  width: 125px;
  font-size: 14px;
  font-weight: 500;
  font-family: $rogers-font;
}
.nodeTglBtn:active {
  transform: translateY(1px);
}
.startButton {
  margin: auto;
  margin-right: 0px;
}
.nodeTabsBar {
  margin-top: 0px;
  padding-left: 20px;
  padding-top: 0;
}
div.nodeTabsCont header.MuiAppBar-colorPrimary {
  color: var(--pureBlack);
  background-color: var(--white);
}
div.nodeTabsCont div.MuiTabs-root {
  min-height: auto;
}
div.nodeTabsCont button.MuiTab-root {
  font-size: 12px;
  padding: 5px;
  min-height: auto;
  font-weight: 700;
}
div.nodeTabsCont button.MuiTab-textColorSecondary.Mui-selected {
  color: var(--pureBlack);
}
div.nodeTabsCont div.MuiBox-root {
  padding: 10px;
}
.modalExternalAppMenu {
  width: 60%;
  position: absolute;
  top: 15%;
  right: 20%;
  left: 20%;
}

.topContainer {
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  margin-bottom: 20px;
  margin-left: 2%;
}

.bottonCont {
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
  margin-bottom: 10px;
  margin-left: 2%;
}

.externalAppText {
  font-family: $rogers-font;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.knowledgeBaseText {
  font-family: $rogers-font;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  justify-items: flex-end;
}

.applicationBox {
  color: rgba(0, 0, 0, 0.5);
  min-height: 95px;
  height: 100px;
  width: 80px;
  flex-direction: column;
  background-color: transparent;
  padding-inline-end: 2%;
  padding-inline-start: 2%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  align-items: center;
  font-size: 15px;
  margin-right: 4%;
  margin-bottom: 4%;
}

.applicationBox:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.applicationBoxWide {
  color: rgba(0, 0, 0, 0.5);
  height: 95px;
  width: 110px;
  flex-direction: column;
  background-color: transparent;
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  align-items: center;
  font-size: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.applicationBoxWide:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.loginFixContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.warningTitle {
  color: var(--danger);
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: italic;
}

.succes {
  color: var(--veryGood);
}

.error {
  color: var(--danger);
}

.errorComment {
  color: var(--white);
  width: 100%;
  background-color: var(--primary);
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding: 10px;
}

.warningText {
  font-style: italic;
  font-size: 15px;
  font-weight: 700;
}
.warnState {
  color: var(--warning);
  font-weight: 600;
}

.loginFixButtonContainer {
  display: flex;
  flex-direction: row;
}

.loginFixButtons {
  background-color: var(--primary);
  border-color: var(--danger);
  margin: 10px;
  height: 55px;
  height: auto;
  width: 100px;
}
.mobileLogInResult {
  text-align: center;
  width: -webkit-fill-available;
}
.profManText {
  align-self: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.profManTitle {
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.profManInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#cancel {
  background-color: var(--white);
  color: var(--greyMedium);
  border-color: var(--greyMedium);
}

.letterInCircle {
  font-family: $rogers-font;
  color: inherit;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  bottom: 63%;
}

.appNameText {
  font-family: $rogers-font;
  font-weight: 700;
  font-size: 12px;
  position: relative;
  top: 5px;
  height: 33px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appMenuBtn {
  background-color: transparent;
  border: none;
  padding: 0px;
  display: flex;
  height: fit-content;
}

.appMenuBtn > svg {
  width: 20px;
  height: 25px;
}
.nodeInfo .appMenuBtn {
  top: 0px;
}

div.externalLinkCont {
  background-color: var(--white);
  text-align: center;
  border-radius: 10px;
  margin: 20px 10px;
  width: 100%;
  border: 1px solid var(--greyLight);
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.extLinkbuttonCont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //width: 50rem;
  margin: auto;
  justify-content: space-between;
  justify-items: auto;
}

.extLinkbutton {
  width: 100px;
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  flex: 0 30%;
  margin-bottom: 1em;
  margin-right: 0.15em;
  text-align: initial;
}

.linkButtonTitle {
  font-family: "Ted Next";
  font-size: 12px;
  font-weight: 600 !important;
  // background-color: transparent;
  color: var(--link);
  border: none;
  // word-break: break-word;
}

.extLinkSearch {
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding-left: 5px;
  width: 40%;
  display: block;
  margin: 15px;
}

div.exceptionCont {
  background-color: var(--white);
  text-align: center;
  border-radius: 10px;
  margin: 20px 10px;
  width: 90%;
  border: 1px solid var(--greyLight);
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.exceptionCont img {
  margin-bottom: 30px;
}
.exceptionCont h4 {
  font-size: 16px;
  font-weight: 600;
}
.exceptionCont p {
  font-size: 14px;
  font-weight: 400;
}
p.errorMsgCont {
  max-height: 200px;
  overflow: auto;
}
.exceptionCont div {
  margin-bottom: 10px;
}
.exceptionCont button.refreshExcBtn {
  font-size: 12px;
  font-weight: 400;
  background-color: var(--link);
  color: var(--white);
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
}
.exceptionCont button.feedbackExcBtn {
  font-size: 10px;
  font-weight: 600;
  background-color: transparent;
  color: var(--link);
  border: none;
  text-decoration: underline;
}
.infoBanner div.MuiAlert-icon {
  align-items: center;
}
.infoBanner p {
  margin-bottom: 0px;
}
.ddbox {
  bottom: 1px;
}
.custDDCont {
  margin-right: 15px;
}
.dropDownBtn {
  font-size: 12px;
}
.custDDBtn b {
  font-weight: 600;
  margin-right: 5px;
}
.custDDCont > .custDDBtn.filterApplied {
  background-color: rgba(218, 41, 28, 0.5);
}
div.optionsList {
  overflow: auto;
  max-height: 170px;
}
div.custDDOption:hover {
  cursor: pointer;
  background-color: rgba(218, 41, 28, 0.1);
}
.custDDSearch {
  border: none;
  width: 85%;
}
.custDDSearch span {
  width: 15%;
}
.custDDSearch:focus {
  outline: none;
}
.custDDOptionsCont div.searchContHead {
  border: 1px solid var(--black75);
  border-radius: 5px;
  padding: 0px 5px;
  margin: 5px 10px;
}
div.custDDOption label {
  margin-bottom: 0px;
}

div.diagAPICont {
  display: flex;
  justify-content: center;
}

.dsValue {
  color: var(--primary);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dsValue img {
  margin-left: 3px;
}
.activeNodeTabSwitch {
  position: relative;
  // z-index: 1;
  flex: 1;
  /* min-height: 96.7vh; */
  background-color: var(--white);
}
.inactiveNodeTabSwitch {
  position: fixed;
  // z-index: 0;
  flex: 1;
}

.nodeSwitchCont {
  position: absolute;
  z-index: 1;
  box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-radius: 5px;
}

.nodeSwitchCont .activeSwitch,
.nodeSwitchCont .inactiveSwitch {
  cursor: pointer;
  padding: 5px 10px;
}
.nodeSwitchCont .activeSwitch {
  background-color: var(--primary);
}
.nodeSwitchCont .inactiveSwitch {
  background-color: var(--white);
}
.nodeSwitchCont .activeSwitch svg,
.nodeSwitchCont .inactiveSwitch svg {
  width: 23px;
  height: 23px;
}
.nodeSwitchCont .activeSwitch svg {
  color: var(--white);
}

.textEllipsisSelectedSpan {
  font-size: 14px;
  color: var(--black);
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
  vertical-align: middle;
  display: inline-block;
}

// @media screen and (max-width: 1266px) {
//   .accTabsBar {
//     padding-left: 0px;
//   }
// }
@media screen and (max-width: 1266px) {
  .nodeTabsBar {
    padding-left: 0px;
  }
  .smHealthScore > span,
  .rightSec span {
    font-size: 14px;
  }
  .scoreDiv span.scoreChip {
    font-size: 11px;
  }
  .summaryCol1 {
    min-width: 210px;
    display: inline-block;
  }
  .nodeBasicInfoSm > .startButtonSection > .startButtonSectionDropdown > div {
    padding-bottom: 3px;
  }
}
@media screen and (max-width: 544px) and (min-width: 320px) {
  .nodeTglBtnCont {
    width: 100vw;
  }
  .lastSec {
    justify-content: space-between;
  }
  .diagButHolder {
    margin: 5px;
  }
  .summaryCol1 {
    min-width: fit-content;
    display: flex;
  }
  .nodeBasicDetailsCont {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 689px) and (min-width: 320px) {
  #nodeID {
    font-size: 14px;
  }
  .nodeDetails,
  .deviceCont {
    font-size: 13px;
    width: auto;
    margin-bottom: 10px;
  }
  .nodeInfo {
    display: inline-block;
  }
  .nodeBasicInfoSm {
    display: inline-block;
  }
  div.nodeContainerSm .nodeInfo {
    display: block;
  }
  .nodeDetailsSm:first-child,
  .nodeDetailsSm:last-child {
    width: auto;
  }
  #nodeNameSM {
    font-size: 18px;
    margin-left: 0px;
  }
  .sADropd {
    margin-left: 5px;
  }
  .colIcon {
    margin-right: 20px;
    margin-bottom: 10px;
    height: 30px;
  }
  #deviceLabel {
    font-weight: 600;
  }
  .deviceBox {
    padding: 5px 10px;
    margin-right: 10px;
  }
  .nodeContainer {
    display: flex;
    padding: 15px;
  }
  .appMenuBtn {
    padding: 0px;
  }
  #custMenu {
    display: flex;
    float: right;
  }
  hr.MuiDivider-vertical {
    width: auto;
    height: 2px;
    margin: 10px 5px;
  }
  button.MuiTab-root {
    font-size: 12px;
    font-weight: 700;
  }
  .nodeDetailsSm:first-child,
  .scoreDiv span,
  .rightSec span {
    font-size: 14px;
  }
  .scoreDiv span.scoreChip {
    font-size: 10px;
  }
  .lastSec {
    justify-content: space-between;
  }
  .summaryCol1 {
    min-width: fit-content;
    display: flex;
  }
  .contSMTsPHUB {
    display: inline-flex;
  }
  .nodeBasicInfoSm > .startButtonSection > .startButtonSectionDropdown {
    display: flex;
    flex-direction: row;
  }
  .nodeContainer
    > div
    > .nodeInfo
    > .startButtonSection
    > .startButtonSectionDropdown {
    flex-direction: row;
    max-width: 100%;
  }
  .nodeBasicDetailsCont {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1005px) and (min-width: 690px) {
  #nodeID {
    font-size: 14px;
  }
  .nodeDetails,
  .deviceCont {
    font-size: 13px;
  }
  #nodeNameSM {
    font-size: 20px;
  }
  .bgIcon {
    height: 30px;
    width: 30px;
  }
  .smIcon {
    font-size: 15px;
  }
  .colIcon {
    margin-right: 10px;
    height: 30px;
  }
  hr.MuiDivider-vertical {
    margin: 10px;
  }
  .deviceBox {
    margin-right: 10px;
  }
  .nodeDetailsSm:first-child,
  .smHealthScore span,
  .rightSec span {
    font-size: 14px;
  }
  .scoreDiv span.scoreChip {
    font-size: 10px;
  }
  .summaryCol1 {
    display: inline-block;
    min-width: 80px;
  }
  .nodeDetails p,
  .nodeDetailsSm p {
    display: block;
  }
  .contSMTsPHUB {
    display: inline-block;
  }
  .nodeContainer
    > div
    > .nodeInfo
    > .startButtonSection
    > .startButtonSectionDropdown {
    display: block;
    max-width: 100px;
  }
  .nodeBasicInfoSm > .startButtonSection > .startButtonSectionDropdown > div {
    padding-bottom: 3px;
  }
  .nodeContainer
    > div
    > .nodeInfo
    > .startButtonSection
    > .startButtonSectionDropdown
    > div.modemTypeContainer {
    display: inline-block;
  }
  .nodeBasicDetailsCont {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1144px) and (min-width: 1006px) {
  .deviceBox {
    margin-right: 10px;
  }
  .colIcon {
    margin-right: 20px;
  }
  .nodeDetailsSm:first-child,
  .smHealthScore > span,
  .rightSec span {
    font-size: 14px;
  }
  .scoreDiv span.scoreChip {
    font-size: 11px;
  }
  .summaryCol1 {
    min-width: 170px;
    display: inline-block;
  }
  .contSMTsPHUB {
    display: inline-block;
  }
  .nodeBasicInfoSm > .startButtonSection > .startButtonSectionDropdown {
    display: inline-flex;
  }
}
@media screen and (min-width: 1266px) {
  .summaryCol1 {
    min-width: 380px;
    display: inline-flex;
  }
  .nodeBasicInfoSm > .startButtonSection > .startButtonSectionDropdown {
    display: inline-flex;
  }
}
