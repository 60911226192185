@import "../globle/css-variables.scss";

.autoContainer {
  position: absolute;
  border: none;
  border-radius: 5px;
  background-color: var(--white);
  width: 100%;
  top: 40px;
  padding: 10px 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 2000;
}
.autoContainer div.searchContHead {
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 20px;
}
.autoContainer div.searchOptionCont {
  max-height: 170px;
  overflow: auto;
}
.searchInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
}
.searchPingContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
}
.clearAllLink {
  color: var(--link);
  cursor: pointer;
}
.option {
  display: flex;
  align-items: center;
  padding: 5px 20px;
}
.option:hover {
  background-color: rgba(218, 41, 28, 0.1);
  cursor: pointer;
}

.historyVal {
  text-align: left;
  width: 90%;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
}
.searchContainer {
  position: relative;
}
.searchImage {
  width: 100%;
  height: 180px;
  position: absolute;
}
.searchBar {
  position: absolute;
  background-color: transparent;
  margin-right: 10px;
  margin-left: 40px;
  width: 45%;
  text-align: center;
}
.searchContainerMT {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
.searchBox {
  width: 100%;
}
.searchPOPBar {
  background-color: transparent;
  margin: 10px;
  text-align: center;
}
.searchInput {
  margin-top: 35px;
  border: 1px solid var(--primary);
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.searchPOPBar .searchInput {
  margin: 10px 0px;
}

.searchPOPBar #searchAccBtn {
  width: 10%;
}
.errorMsg {
  color: var(--primary);
  font-family: $rogers-font;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  text-align: left;
}

.errorMsg ul {
  padding-left: 15px;
  margin-bottom: 0px;
}

.MuiAlert-root.errorAlert {
  font-family: $rogers-font;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  z-index: 500;
  border-radius: 20px;
  position: relative;
}

#searchInput-dropdown {
  background: transparent;
  color: var(--white);
  border: transparent;
  font-weight: 600;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
#searchInput-dropdown:focus {
  outline: none;
  box-shadow: none;
}
.searchInput-Text,
.searchInput-WO {
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  height: 35px;
}
.searchInputLabel {
  width: 25%;
  background-color: var(--primary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px 0px 0px 30px;
}
#searchAccBtn {
  background-color: var(--primary);
  border-radius: 0px 30px 30px 0px;
  border-color: transparent;
  padding: 0px 10px;
  height: 35px;
  width: 10%;
}
div.searchBar div.dropdown,
div.searchBox div.dropdown,
div.searchPOPBar div.dropdown {
  display: flex;
  width: 25%;
  height: 35px;
  background-color: var(--primary);
  justify-content: center;
  align-items: center;
  border-radius: 30px 0px 0px 30px;
}
.dropdown-menu {
  border: 1px solid var(--primary);
  max-height: 200px;
  overflow: auto;
}
.dropdown-item {
  color: var(--primary);
}
.dropdown-item:hover {
  color: white;
  background-color: var(--primary);
}
.checkBoxCont {
  display: flex;
  margin: 10px -5px;
  font-size: 12px;
  font-weight: 600;
}

.WHTCheck .MuiCheckbox-root {
  font-size: 16px;
  padding: 0px;
  font-weight: 600;
}
.WHTCheck .MuiTypography-body1 {
  font-size: 12px;
  padding: 0px 10px;
  font-weight: 600;
}
.addressCards {
  margin-bottom: 10px;
}
.addressCards p {
  margin-bottom: 0px;
}
.addressCards:hover {
  background-color: var(--primary);
  color: var(--white);
  cursor: pointer;
}
.errorBox {
  margin-top: 50px;
}

div.snackBarPods,
div.snackBarPods .MuiAlert-root {
  top: 20%;
  left: 50%;
  transform: translate(-25%, -50%);
  height: 150px;
  width: 600px;
  z-index: 3000;
  font-size: 22px;
}

div.snackBar,
div.snackBar .MuiAlert-root {
  width: 100%;
  top: 50px;
  border-radius: 0px;
  z-index: 3000;
}
div.snackBar .MuiAlert-filledError {
  background-color: var(--danger);
}
.errorBox div.MuiSnackbarContent-root {
  background-color: var(--danger);
}
.confirmIcon {
  color: var(--primary);
  font-size: 60px;
  display: flex;
  margin-left: 10px;
}
.confirmBody {
  align-items: center;
  display: flex;
}
div.confirmBodyCont {
  width: 85%;
  padding-left: 20px;
  margin: 10px 0px;
}
#sAPopUp {
  font-family: $rogers-font;
}
div#sAPopUp .resultCard {
  width: 90%;
}
div#customPopUp {
  max-width: 50vw;
}

///loader Css

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
}
.loader {
  left: 45%;
  top: 45%;
  z-index: 1000;
  position: absolute;
}
.loaderImg {
  /* margin-top: 10%;
  margin-left: 40%; */
  height: 60px;
}

.chipActive {
  font-weight: 700;
  font-size: 11px;
}
.chipInactive {
  font-weight: 700;
  font-size: 11px;
}

.chipsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.gponChipsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding: 2px 0px;
}

.gponChipsTitle {
  color: var(--primary);
  font-weight: 700;
  padding-left: 8px;
  padding-right: 16px;
  font-size: 14px;
}

//New Search Box CSS
section.searchTabsheader {
  display: flex;
  margin: 5px 10px;
  border-bottom: 1px solid var(--greyLight);
}
section.searchTabsheader .custom-selectedDeviceTab-Tab {
  border-bottom: 3px solid var(--primary);
  border-left: none;
}
section.searchTabsheader .horizontalDeviceTab {
  padding: 5px 15px;
}
section.searchTabsheader .horizontalDeviceTab:hover {
  cursor: pointer;
}
section.searchBoxInputCont div.MuiBox-root {
  padding: 5px 10px !important;
}
div.seachInputSeleCont {
  margin-bottom: 10px;
}
div.seachInputSeleCont,
div.seachInputSeleCont span {
  display: flex;
  align-items: center;
  flex-direction: row;
}

div.seachInputSeleCont input[type="radio"],
div.seachInputSeleCont svg {
  margin: 0px 5px;
  top: 0px;
  cursor: pointer;
}
div.seachInputSeleCont label {
  font-size: 13px;
  margin: 0px;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 500;
}
div.seachInputSeleCont label:first-child {
  font-weight: 600;
  min-width: 70px;
}
div.searchInputCont div.searchInput {
  margin: 0px;
  width: 60%;
}
div.searchInputCont span.searchInputLabel {
  width: 20%;
  font-size: 13px;
}
div.searchPingInputCont div.searchInput {
  margin: 0px;
  width: 40%;
}
div.searchPingInputCont span.searchInputLabel {
  width: 20%;
  font-size: 12px;
}
.custSearchPingInputBox, .subtextLabel {
  display: flex;
  align-items: center;
  gap: 10px; 
}

div.custSearchInputBox {
  display: flex;
  width: 30%;
  flex-direction: column;
}
section.searchPOPUpInputCont div.custSearchInputBox {
  width: 70%;
}
.custSearchPingInputBox {
  display: flex;
  align-items: center;
  width: 20%; 
  margin-bottom: 10px; 
}
section.searchPOPUpInputCont div.custSearchPingInputBox {
  width: 20%;
}
.searchPingInputCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row {
  display: flex;
  align-items: left;
  gap: 5px;
}

.custPingSearchInput {
  // flex-grow: 0.5; 
  // min-width: 10px;
}

div.custTypeSeleCont {
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  flex-direction: row;
}
div.custTypeSeleCont div.topologySec {
  display: flex;
  align-items: center;
  flex-direction: row;
}
div.custTypeSeleCont label {
  font-weight: 600;
  margin-bottom: 0px;
}
div.custTypeSeleCont div.accTypeOptions {
  display: flex;
  margin-left: 10px;
}
span.seleAccTypeSpan {
  background-color: #3498db;
  color: #ffffff;

}
span.seleTypeSpan {
  background-color: #3498db;
  color: #ffffff;
  height: 30px;
  // padding: 4px 15px;
  // border-radius: 10px;
}
div.accTypeOptions hr {
  width: 1px;
  height: auto;
}
div.custTypeSeleCont span {
  padding: 2px 10px;
  cursor: pointer;
  min-width: 75px;
  font-weight: 500;
  text-align: center;
  background-color: var(--black10);
  border-radius: 10px;
  margin: 0px 5px;
}

div.custTypeSeleCont span:hover {
  background-color: var(--primary);
  color: var(--white);
}

div.custTypeSeleCont span.seleAccTypeSpan {
  background-color: var(--primary);
  color: var(--white);
}
div.custTypeSeleCont span.seleTypeSpan {
  background-color: var(--primary);
  color: var(--white);
}
input.custSearchInput {
  border-radius: 10px;
  border: 1px solid var(--greyLight);
  padding: 3px 5px;
}
input.custSearchInput:focus {
  outline: none;
  box-shadow: none;
}

input.custPingSearchInput {
  border-radius: 10px;
  border: 1px solid var(--greyLight);
  width:225px;
  height:30px;
  //  padding-left: 3px;
  // padding-top:3px;
}
input.custPingSearchInput:focus {
  outline: none;
  box-shadow: none;
}
input.custNoPingSearchInput {
  border-radius: 10px;
  border: 1px solid var(--greyLight);
  width:50px;
  height:30px;
  //  padding-left: 3px;
  // padding-top:3px;
}
input.custNoPingSearchInput:focus {
  outline: none;
  box-shadow: none;
}
div.searchInputCont {
  display: flex;
  align-items: center;
}

.searchInputCont button.customSearchBtn {
  border: none;
  width: 5%;
  background: var(--primary);
  color: var(--white);
  padding: 4px 15px;
  border-radius: 10px;
  margin-left: 10px;
}
.textLabel {
  font-size: 12px;
  margin-right: 25px;
  margin-left: 15px;
}
.subtextLabel {
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  color: red;
}
section.searchPOPUpInputCont .searchInputCont button.customSearchBtn {
  width: 15%;
}
section.searchBoxInputCont {
  padding: 10px 10px 0px 10px;
}
section.searchPOPUpInputCont {
  padding: 0px;
}
section.searchBoxInputCont div.multiDeviceSearchCont,
section.searchPOPUpInputCont div.multiDeviceSearchCont {
  border-radius: 10px;
  padding: 10px;
  background-color: var(--background);
  border: 1px solid #dee2e6;
  box-shadow: 0 1px 10px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 544px) {
  .searchBar,
  .searchBox {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px 20px;
  }
  .searchIcon,
  .searchInput {
    margin-top: 40px;
  }
  .searchImage {
    height: 180px;
  }
}
@media screen and (min-width: 1440px) {
  .searchImage {
    height: 300px;
  }
  .searchIcon,
  .searchInput {
    margin-top: 100px;
  }
}
@media screen and (max-width: 689px) and (min-width: 320px) {
  .dropdown-menu {
    font-size: 12px;
  }
  #searchAccBtn {
    padding: 0px 10px 0px 0px; // Added search icon alignment in lower resolutions
  }
}
@media screen and (max-width: 889px) and (min-width: 545px) {
  .searchBar,
  .searchBox {
    width: 55%;
  }
}
.coordinatesErrorBox {
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 2%;
  text-align: center;
}
.tabsContainer {
  display: flex;
  justify-content: start;
  align-items: center;
}

.tab {
  cursor: pointer;
  padding: 12px 12px;
  position: relative; 

}
.tab:before {
  content: "";
  position: absolute;
  right: 0;
  top: 25%; /* Adjust to move the line up or down */
  bottom: 25%; /* Adjust to control the length of the line */
  width: 1px;
  background-color: red;
}
.tab:last-child {
  border-right: none; /* Removing border for the last tab */
}

.tab:last-child:before {
    content: none; /* Removing line for the last tab */
}
.activeTab {
  border-bottom: 2px solid red; /* Underline for active tab */
  position: relative;
  font-weight: bold;
 
}


.tabDivider {
  width: 100%;
  border: none;
  border-top: 1px solid grey; /* Normal horizontal line */
  margin: 5px 0; 
}
