@import "../../design-tokens/globle/css-variables.scss";

//SelectionChip.tsx
.selectChipCont,
.selectChipContChecked {
  border-width: 1.5px;
  border-radius: 25px;
  border-style: solid;
  padding: 3px 5px;
  flex-direction: row;
  align-items: center;
  display: flex;
  min-width: 110px;
}
.selectChipCont {
  border-color: var(--greyDark);
}
.selectChipContChecked {
  border-color: var(--primary);
  background-color: var(--primary20);
}
.selectTxtChipCont {
  margin: 0;
  font-size: small;
  color: var(--greyDark);
  font-weight: 600;
}
.selectChipTxtChecked {
  margin: 0;
  font-size: small;
  color: var(--primary);
  font-weight: 600;
}
.selectChipCont,
.selectChipContChecked,
.selectTxtChipCont,
.selectChipTxtChecked {
  cursor: pointer;
}
.selectChipCont svg,
.selectChipContChecked svg {
  margin-right: 3px;
}

//NumberInputStepper.tsx
.stepperInput {
  align-items: center;
}
.stepperInput input#stepperEleInput {
  margin: 0px 5px;
  border: 1px solid var(--primary);
  border-radius: 10px;
  min-width: 50px;
  padding: 0px;
  height: 30px;
}
.stepperInput input#stepperEleInput:focus,
.stepperInput #stepperMapEleBtn:focus {
  box-shadow: none;
}
.stepperInput #stepperMapEleBtn {
  padding: 0;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  font-size: 18px;
  background-color: var(--primary20);
  color: var(--primary);
}
.stepperInput #stepperMapEleBtnDis {
  padding: 0;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  font-size: 18px;
  background-color: var(--greyLight);
  color: var(--greyDark);
}
.stepperInput-Text {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.stepperSubmitBtn,
.stepperSubmitBtn:focus,
.stepperSubmitBtn:hover {
  background-color: var(--primary);
  color: var(--white);
  border: 0;
  padding: 0px 10px;
  font-size: 14px;
  border-radius: 10px;
  font-weight: 600;
  // margin-left: 5px;
  height: 30px;
  box-shadow: none;
}
.stepperSubmitBtn:disabled{
  background-color: var(--greyMedium);
}

.numStepperCont {
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  padding: 0px;
  box-shadow: 1px 0px 5px -2px var(--black50);
}
.numStepperInput {
  border: none;
  padding: 2px;
  height: auto;
  font-size: 13px;
  text-align: center;
}
.numStepperInput:focus {
  border: none;
  box-shadow: none;
}
.stepperBtnGroup {
  display: flex;
  flex-direction: column;
}
.stepperBtnGroup span {
  display: flex;
  height: 50%;
  padding: 0px 5px;
}
.stepperBtnGroup span.stepperBtn:hover {
  background: var(--background);
  cursor: pointer;
}
.stepperBtnGroup span.disableBtn {
  background: var(--greyLight);
}
.stepperBtnGroup span.disableBtn:hover {
  cursor: not-allowed;
}
.stepperBtnGroup svg {
  font-size: 15px;
}

//ExceptionHandlerWrapper.tsx ===============
.exceptionHandlerWrapperCont {
  box-shadow: 0px 6px 8px 4px rgba(0, 0, 0, 0.25);
}
.exceptionHandlerWrapperCont .errorIconCont {
  padding: 30px;
  background-color: var(--danger);
  display: flex;
  justify-content: center;
}
.exceptionHandlerWrapperCont .errorIconCont svg {
  width: 25%;
  height: 25%;
  color: var(--white);
}
.exceptionHandlerWrapperCont .messageCont {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.exceptionHandlerWrapperCont .messageCont label {
  font-weight: 600;
  font-size: large;
}
.exceptionHandlerWrapperCont .messageCont p {
  max-height: 200px;
  overflow: auto;
  margin: 0;
}
.exceptionHandlerWrapperCont .messageCont button {
  background-color: var(--link);
  color: var(--white);
  border-radius: 50px;
  padding: 10px 25px;
  margin-top: 15px;
  border-width: 0;
  font-weight: 600;
  box-shadow: inset 0 0 0 0 var(--info);
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.exceptionHandlerWrapperCont .messageCont button:hover {
  box-shadow: inset 100px 0 0 0 var(--info);
}
//End of ExceptionHandlerWrapper.tsx =============================

.customTRCell svg{
  width: 20px;
  height: 20px;
  color: var(--link);
  margin: 5px;
}
.customTRCell svg:hover{
  color: var(--primary);
}
.customTRCell span{
  color: var(--link);
  cursor: pointer;
  text-decoration: underline;
}
.customTRCell span:hover{
  color: var(--primary);
}
.customTRCell section{
  display: flex;
  flex-direction: row;
  align-items: center;
}

//Toggle Switch Component=======================
.toggleSwitch {
  display: flex;
}
.toggleSwCon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 0px 5px;
}
.toggleSwSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--greyLight);
  transition: 0.4s;
  border-radius: 34px;
}
.toggleSwCon input {
  display: none;
}
.toggleSwCon input:checked + .toggleSwSlider {
  background-color: var(--primary);
}
.toggleSwCon input:checked + .toggleSwSlider::before {
  transform: translateX(20px);
}
.toggleSwSlider::before {
  position: absolute;
  content: "";
  left: 2px;
  bottom: 2px;
  height: 16px;
  width: 16px;
  background-color: var(--white);
  transition: 0.4s;
  border-radius: 50%;
}
//End of Toggle Switch Component=======================

.flexedRowDiv{
  display: flex;
  align-items: center;
  flex-direction: row;
}

//PaginatedTable.tsx
.paginatedTableCont{
  justify-content: space-between;
  margin: 5px 0;
  flex: 1;
}
.paginatedTableCont span{
  font-size: 14px;
  font-weight: 600;
}
.paginatedTableCont .pagingNumCont{
  margin-left: 10px;
}
.paginatedTableCont .pagingNumCont span{
  padding: 4px 8px;
  margin-right: 5px;
  border: 1px solid var(--greyLight);
  border-radius: 5px;
}
.paginatedTableCont .pagingNumCont span:hover{
  cursor: pointer;
  background-color: var(--primary20);
}
.paginatedTableCont .pagingNumCont .activeSpan{
  background-color: var(--primary);
  color: var(--white);
}
.basicTableHeaderIcon{
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.basicTableHeaderIcon:hover{
  color: var(--primary);
}
//End of PaginatedTable.tsx

#selectDropdownBtn {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  height: 35px;
}
#selectDropdownBtn:focus{  
  box-shadow: none;
}

//TreeView.tsx
.treeViewCont{
  // border: 1px solid var(--primary);
  border-radius: 5px;
  box-shadow: 0px 6px 8px 4px rgba(0, 0, 0, 0.25);
  padding: 5px 10px;
}
.treeViewCont .treeViewHeader{
  justify-content: space-between;
}
.treeViewCont .treeViewTitle{
  margin: 0px;
  font-weight: 600;
}
.treeViewCont .treeViewCollapseBtn{
  margin: 0px;
  font-weight: 600;
  color: var(--link);
  font-style: italic;
}
.treeViewCont .treeViewCollapseBtn:hover{
  cursor: pointer;
}
.treeItemCont .treeItemLabel{
  margin: 0;
  margin-left: 5px;
}
.treeItemCont .treeItemCollapse:hover{
  cursor: pointer;
}
.treeItemCont .treeItemSelected{
  background-color: var(--primary10);
}
//End of TreeView.tsx

// CustomAccordion.tsx

.accordionToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.accordionToggle div {
  display: flex;
  align-items: center;
}